import { put, post, delete as del } from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL


export const createDeliverable = (id) => {
	return (dispatch, getState) => {
    
    const state = getState()
		const { auth } = state;
		const { user } = auth;
    const { token } = user;
		
    const postParams = {
			deliverable: {
				title: ''
			}
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}
		return post(`${API_BASE_URL}/api/v1/projects/${id}/deliverables`,postParams, config)
    }
}

export const createComment = (values,id) => {
	return (dispatch, getState) => {
    
    const state = getState()
		const { auth } = state;
		const { user } = auth;
    const { token } = user;
		
    const postParams = {
			comment: {
				...values
			}
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}
		return post(`${API_BASE_URL}/api/v1/deliverables/${id}/comments`,postParams, config)
    }
}

export const updateDeliverable = (values,id) => {
	return (dispatch, getState) => {
    
    const state = getState()
		const { auth } = state;
		const { user } = auth;
    const { token } = user;
		
    const postParams = {
			deliverable: {
				...values
			}
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}
		return put(`${API_BASE_URL}/api/v1/deliverables/${id}`,postParams, config)
    }
}

export const updateCheckList = ({id, status, checklistText: checklist_text, dueDate: due_date,}) => {
	return (dispatch, getState) => {
    
    const state = getState()
		const { auth } = state;
		const { user } = auth;
    const { token } = user;
		
    const postParams = {
			checklist: {
					status,
					checklist_text,
					due_date
			}
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}
		return put(`${API_BASE_URL}/api/v1/checklists/${id}`,postParams, config)
    }
}

export const addCheckList = ({ checklistText: checklist_text, dueDate: due_date, status, },id) => {

	return (dispatch, getState) => {
    const state = getState()
		const { auth } = state;
		const { user } = auth;
    const { token } = user;
		
    const postParams = {
			checklist: {
				checklist_text,
				due_date,
				status
			}
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}
		return post(`${API_BASE_URL}/api/v1/deliverables/${id}/checklists`,postParams, config)
    }
}

export const deleteCheckList = (id) => {

	return (dispatch, getState) => {
    
    const state = getState()
		const { auth } = state;
		const { user } = auth;
    const { token } = user;

		let config = {
			headers: {
				'Authorization': token
			}
		}
		return del(`${API_BASE_URL}/api/v1/checklists/${id}`, config)
    }
}
