import React from 'react';
import { graphql } from 'react-apollo';
import fetchUser from '../../graphQL/Query/fetchUser'
import Client from '../../pages/Client';
import Incredible from '../../pages/Incredible';
import { Route } from 'react-router-dom';
import Loader from '../../Components/Loader';
import Dashboard from '../../Components/Dashboard';
import AppsIcon from '@material-ui/icons/Apps';
import AddIcon from '@material-ui/icons/Add';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import CallIcon from '@material-ui/icons/Call';
import AccountIcon from '@material-ui/icons/AccountCircle';
import NavItems from '../../Components/NavItems';

function LoggedInRoutes(props) {

  const { data, auth, location } = props;
  const { loading } = data;
  const { user } = auth;
  const { id } = user;

  const clientNav = [
    // { label: 'Projects', icon: <AppsIcon />, to: '/' },
    // { label: 'Add New Project', icon: <AddIcon />, to: '/create/project' },
    // { label: 'Settings', icon: <SettingsIcon />, to: '/settings' },
    // { label: 'Contact Us', icon: <CallIcon />, to: '/contactus' },

    { label: 'Project Overview',projectLink:true, icon: <AppsIcon />, to: `/project/projectId` },
    { label: 'Incredibles',projectLink:true, icon: <PeopleIcon />, to: `/project/projectId/incredibles` },
    { label: 'Project Management',projectLink:true, icon: <SettingsIcon />, to: `/project/projectId/management` },
  ]

  const incredibleNav = [
    { label: 'Profile', icon: <AccountIcon />, to: `/user/${id}/profile` },
    { label: 'Project Management', icon: <AppsIcon />, to: '/management' },
    { label: 'Community', icon: <PeopleIcon />, to: '/community' },
    { label: 'Matched Projects', icon: <AddIcon />, to: `/user/${id}/matches` },
  ]

  if(loading)
    return <Loader/>

  const { user: userData } = data;
  const { roles } = userData;
  const client = roles.filter(({ name }) => name === 'Client');
  // const client = [];

  return (
    <div style={styles.container}>
      {
      client.length > 0 ?
      <Dashboard myListItems={() => <NavItems list={clientNav} path={location.pathname}/>} {...props}>
          <Route path="/" component={(params) => { return <Client {...props} {...params}/> }}/>
        </Dashboard> :
        <Dashboard myListItems={() => <NavItems list={incredibleNav} path={location.pathname}/>} {...props}>
          <Route path="/" component={(params) => { return <Incredible {...props} {...params}/> }}/>
        </Dashboard>
      }
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    boxSizing: 'border-box',
    flex: 1,
  },
}

export default graphql(fetchUser,{
  options: (props) => {
    return {variables: {id: props.auth.user.id}}
  }
})(LoggedInRoutes)
