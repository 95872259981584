import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Grid, Typography, ExpansionPanel, ExpansionPanelSummary, Divider, ExpansionPanelDetails } from '@material-ui/core';

const Panel = ({ data, children }) => {

	const renderDataRows = data.map(({label, value, alignItems='flex-start', xs = true},index) => {
		return (
			<Grid key={index} item xs={xs}>
				<Grid container direction="column" alignItems={alignItems} justify="center" spacing={2}>
					<Grid item>
					{
						typeof(label) === 'string' ?
						<Typography variant="body2">
							{label}
						</Typography>
						: <div>{label}</div>
					}
					</Grid>
					<Grid item>
						<Typography>
							{value}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		)
	})

	return (
		<React.Fragment>
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Grid container justify="space-between" spacing={2}>
							{ renderDataRows }
					</Grid>
				</ExpansionPanelSummary>
				<Divider />
				<ExpansionPanelDetails>
					<Grid container direction="column" spacing={2}>
						{ children }
					</Grid>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</React.Fragment>
	)
}

export default Panel