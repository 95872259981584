import React from 'react'

const List = ({ text }) => {
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center'
    },
    circle: {
      width: 18,
      height: 18,
      backgroundColor: '#d6d6d6',
      marginRight: 10,
      borderRadius: '50%',
    },
    text: {
      fontSize: 14,
      color: '#7a7a7a',
      marginRight: 20,
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.circle} />
      <div style={styles.text}>
        { text }
      </div>
    </div>
  )
}

export default List