import { put, post } from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const createProject = ({
		projectName: title,
		projectDescription: description,
		domains: domain,
		projectStage: stage_at,
		platforms: target_platform,
		references
	}, refetch ) => {
	return (dispatch, getState) => {

    const state = getState()
		const { auth } = state;
		const { user } = auth;
    const { token, id } = user;

    const postParams = {
			project: {
				title,
				description,
				domain,
				stage_at,
				target_platform,
			},
			references
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return post(`${API_BASE_URL}/api/v1/users/${id}/projects`,postParams, config).then(resp => {
			refetch();
		}).catch(error => {
			console.log('error',error);
		})
  }
}


export const createTechnicalRequirement = (values, projectId) => {
	return (dispatch, getState) => {

		const state = getState()
		const { auth } = state;
		const { user } = auth;
		const { token } = user;

		const postParams = {
			technical_requirement: {
				project_id: projectId,
				...values
			},
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return post(`${API_BASE_URL}/api/v1/projects/${projectId}/technical_requirements`,postParams, config);
	}
}

export const updateMatch = (status, id) => {
	return (dispatch, getState) => {

		const state = getState()
		const { auth } = state;
		const { user } = auth;
		const { token } = user;

		const postParams = {
			status
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return put(`${API_BASE_URL}/api/v1/matches/${id}`,postParams, config);
	}
}

export const updateClientMatch = (status, id) => {
	return (dispatch, getState) => {

		const state = getState()
		const { auth } = state;
		const { user } = auth;
		const { token } = user;

		const postParams = {
			status
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return put(`${API_BASE_URL}/api/v1/projects/${id}/client_matches`,postParams, config);
	}
}
