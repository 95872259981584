import gql from 'graphql-tag'

export default gql`
query FetchUser($id: ID!) {
  user(id: $id) {
    email
    id
    profile {
      id
      profilePicture
      role
    }
    projects {
      id
      description
      title
    }
    roles {
      description
      id
      name
    }
    username
  }
}
`
