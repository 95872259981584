import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Calendly from '../../../../components/Shared/Calendly';
import RejectedIcon from '@material-ui/icons/Close';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  ExpansionPanelSummary,
  ExpansionPanel,
  ExpansionPanelDetails,
  Button,
} from '@material-ui/core';
import MyAvatar from '../../../../../Components/MyAvatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Panel from '../../../../../Components/Panel';
import { connect } from 'react-redux';
import { updateMatch } from '../../../../../redux/actions';

const Match = ({ user, status, updateStatus, projectId, id }) => {
  const styles = {
    container: {
      display: 'flex',
    },
    state: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: '100%',
      padding: 5,
    },
  }
  let isCTA = status === 'cta' || status === 'get_estimate' ? true : false
  let isGetEstimate = status === 'get_estimate' ? true : false

  const {
    username,
    profile
  } = user;

  const {
    availability,
    region,
    timezone,
    minBidRate,
    overview
  } = profile;

  if(!status || status === 'reject')
    return null

  return (
    <React.Fragment>
      <Grid container item alignItems="center" justify="space-between" spacing={2}>
        <Grid container justify="center" item xs>
          <Link to={{
            pathname: "incredible/"+user.id,
            state: {
              user,
            }}} className="col-md-2">
            <MyAvatar size="large" hasMenu={false} user={user}/>
          </Link>
        </Grid>
        <Grid item xs>
          <Typography>{username}</Typography>
          <Typography variant={'body2'}>{overview}</Typography>
        </Grid>
        <Grid item xs>
          <Typography>{region ? region : '{REGION}'}</Typography>
          <Typography variant={'body2'}>{timezone ? timezone: 'timezone'}</Typography>
        </Grid>
        <Grid container justify="center" item xs>
          <Typography>{"$"+minBidRate ? minBidRate : 'N/A'}</Typography>
          <Typography variant={'body2'}>min rate/hr</Typography>
        </Grid>

        <Grid container direction="column" item xs spacing={2} alignItems="center" justify="center">
          {
            user.status !== 'reject_client' ?
              <React.Fragment>
              <Grid item justify="center" container>
                <p className="card-incredible-button pull-right">{ availability === true ? 'Available' : 'Unavailable' }</p>
              </Grid>
              <Grid item justify="center" container>
                {
                  isCTA ?
                  <p className="card-incredible-button pull-right">Call scheduled</p> :
                  <Button variant="contained" data-toggle="modal" data-target="#calendly-modal" id="demo-bootbox-ruberwobble" onClick= {() => updateStatus('cta',id)}>Set up call</Button>
                }
              </Grid>
              <Grid item justify="center" container>
                {
                  isGetEstimate ?
                  <p className="card-incredible-button pull-right">Estimate Requested</p> :
                  <Button variant="contained" disabled={!isCTA} onClick= {() => updateStatus('get_estimate',id)}>Get Estimate</Button>
                }
              </Grid>
              <Grid item justify="center" container>
                <Button variant="outlined" onClick= {() => updateStatus('reject_client',id)}>Reject</Button>
              </Grid>
              </React.Fragment>
            :
            <div style={styles.state}><img style={styles.stateImg} src={RejectedIcon}/><div>Match Rejected</div></div>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  )
}


const TechnicalRequirement = ({ data, updateStatus, projectId }) => {

  let count = 0
  const {
    talentType,
    talentMatches = [],
    technicalExpertise,
    id
  } = data

  talentMatches.map(match => {
    return match.status !== null && match.status !== 'reject' ? count++ : null
  })

  const dataRows = [
    { label: 'Incredibles Type', value: talentType},
    { label: 'Specialization', value: technicalExpertise},
    { label: 'Incredibles needed', value: 1},
    { label: 'Matches', value: count },
  ]

  return (
    <Panel data={dataRows}>
    {
      talentMatches.map((match,index) => {
        return (
          <React.Fragment key={match.id}>
            <Match updateStatus={updateStatus} projectId={projectId} {...match}/>
            {
              index + 1 < talentMatches.length ? <Grid item><Divider/></Grid> : null
            }
          </React.Fragment>
        )
      })
    }
    </Panel>
  )
}

class Incredible extends Component {

  updateStatus = (status, id) => {
    this.props.updateMatch(status,id).then(resp => {
      this.props.data.refetch();
    });
  }

  render() {
    const { data } = this.props;
    const { project } = data;

    let { technicalRequirements, id } = project

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Incredibles
        </Typography>
        <Grid container spacing={4}>
          <Grid item>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Incredible Matches against requirements
                </Typography>
                <Divider/>
                  {
                    technicalRequirements.length === 0 &&
                    <p>There are no Incredible matches against your talent requirements as yet.</p>
                  }
                  {
                    technicalRequirements.map(req => <TechnicalRequirement key={req.id} projectId={id} data={req} updateStatus={this.updateStatus}/>)
                  }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Calendly /> */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps,{updateMatch})(Incredible)
