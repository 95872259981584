import gql from 'graphql-tag'

export default gql`
query FetchProject($id: ID!) {
  project(id: $id){
    callScheduled
    deliverables {
      assignee {
        id
        username
        profile {
          id
          profilePicture
        }
      }
      id
      description
      checklists {
        id
        status
        checklistText
        dueDate
      }
      comments {
        id
        description
        user {
          id
          username
        }
      }
      title
    }
    description
    domain
    id
    stageAt
    targetPlatform
    technicalRequirements {
      duration,
      domain,
      id,
      startDate,
      talentType,
      languages,
      technicalExpertise,
      tools,
      project {
        id,
        title
      }
      talentMatches {
        id
        status
        user {
          id
          username,
          profile {
            id
            timezone,
            availability,
            minBidRate,
            overview,
          }
        }
      }
      technicalExpertise
    }
    title
    userId
  }
}
`
