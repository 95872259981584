import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { graphql } from 'react-apollo';
import fetchProject from '../../../../graphQL/Query/fetchProject';
import View from './Components/View';
import Management from './Components/Management';
import Loader from '../../../../Components/Loader'
import MenuItems from './Components/MenuItems';
import Grid from '@material-ui/core/Grid';
import Incredibles from './Components/Incredibles';
import IncredibleView from './Components/IncredibleView';
import AddTechnicalRequirement from './Components/AddTechnicalRequirement';

const styles = {
  row: {
    display: 'flex',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  }
}
const Project = (props) => {
  const { data } = props;
  const { loading, project = {} } = data

  if(loading)
    return <Loader/>

  return (
    <React.Fragment>
      <div style={styles.row}>
        <Grid container spacing={2}>
          <Grid item>
            {/* <MenuItems {...props}/> */}
          </Grid>
          <Grid item xs>
            <Switch>
            <Route exact path="/project/:id/management" component={() => <Management {...props} />} />
            <Route exact path="/project/:id/incredible/:userId" component={(match) => <IncredibleView {...match} />} />
            <Route exact path="/project/:id/incredibles" component={() => <Incredibles data={data}/>} />
            <Route exact path="/project/:id/createRequirement" component={() => <AddTechnicalRequirement {...props} data={data}/>} />
            <Route exact path="/project/:id" component={() => <View {...props} data={{project}}/>} />
            {/* <Route exact path="/project/:id/incredibles" component={()=> <Incredibles data={{project}}/>} /> */}
            {/* <Route exact path="/project/:id/management" component={ProjectManagement} /> */}
            {/* <Route exact path="/project/:id/edit" component={ProjectForm} />
            <Route exact path="/project/:id/tpm-complete" component={PostTPM} />
            <Route exact path="/project/:id/incredible/:userid" component={IncredibleProfile} />
            <Route exact path="/project/:id/created" component={ProjectCreated} />
            <Route       path="/project/:id/new-requirement/" component={WizardForm} />
            <Route exact path="/project/:id/creation-complete" component={ProjectCreationComplete} />
            <Route exact path="/project/:id/tpm-requirement" component={TechnicalProjectManagerForm} />
            <Route exact path="/project/:id/ops-team-call-schedule" component={ProjectCallScheduleWithOpsTeam} />
            <Route exact path="/project/:id/tpm-requirement/:tpmid/edit" component={TechnicalProjectManagerForm} />
            <Route exact path="/project-creation-complete" component={ProjectCreationComplete} /> */}
            </Switch>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default graphql(fetchProject,{
  options: (props) => {
    return {variables: {id: props.match.params.id}}
  }
})(Project)
