import gql from 'graphql-tag'

export default gql`
query assignedProjects($id: ID!) {
	assignedProjects(id: $id) {
		id
		title
		deliverables {
			assignee {
			  id
			  username
			  profile {
					id
					profilePicture
			  }
			}
			id
			description
			checklists {
			  id
			  status
			  checklistText
			  dueDate
			}
			comments {
			  id
			  description
			  user {
					id
					username
			  }
			}
			title
		}
	}
}
`