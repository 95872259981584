import React from 'react'
import Dropdown from '@material-ui/icons/ExpandMore';
import DropdownClose from '@material-ui/icons/ExpandLess';
import Moment from 'react-moment'
import AcceptedIcon from '@material-ui/icons/Check';
import RejectedIcon from '@material-ui/icons/Close';
import { graphql } from 'react-apollo';
import fetchUserMatches from '../../../../graphQL/Query/fetchUserMatches';
import Loader from '../../../../Components/Loader';
import { Typography, Grid, Card, CardContent, Divider, Button } from '@material-ui/core';
import Panel from '../../../../Components/Panel';
import MyAvatar from '../../../../Components/MyAvatar';
import Detail from '../../../../Components/Profile/Components/Project/Components/Detail';
import List from '../../../../Components/Profile/Components/Project/Components/List';
import { updateMatch } from '../../../../redux/actions';
import { connect } from 'react-redux';

const ProjectView = ({ data }) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      borderBottom: '1px solid #d6d6d6' 
    },
    heading: {
      fontSize: 22,
      marginBottom: 20,
      color: '#444444',
      marginTop: 20,
    },
    row: {
      display: 'flex',
      width: '50%',
      justifyContent: 'space-between'
    }
  }
  
  const { project, domain: reqDomain, tools, startDate, duration, languages, technicalExpertise, timezone } = data;
  const { domain, targetPlatform, title, description, stageAt  } = project
  
  return (
    <Grid container spacing={2} >
      <Grid item>
        <Typography gutterBottom variant="h5">{title}</Typography>
      </Grid>
      <Detail heading={'Description'} text={description}/>
      <Detail heading={'Domains'} text={domain.map((text,index) => <List key={index} text={text}/>)}/>
      <Detail heading={'Target Platforms'} text={targetPlatform.join(', ')}/>
      <Detail heading={'Current Project Stage'} text={stageAt}/>
      <Grid item>
        <Typography gutterBottom variant="h5">Requirement Details</Typography>
      </Grid>
      {/* <Detail heading={'Description'} text={'N/A'}/> */}
      <Detail heading={'Specialties'} text={technicalExpertise.map((text,index) => <List key={index} text={text}/>)}/>
      <Grid item container spacing={2}>
        <Grid item xs>
          <Detail heading={'Required Domain Experience'} text={reqDomain.map((text,index) => <List key={index} text={text}/>)}/>
        </Grid>
        <Grid item xs>
          <Detail heading={'Preferred experience of Tools and Softwares'} text={tools.map((text,index) => <List key={index} text={text}/>)}/>
        </Grid>
        <Grid item xs>
          <Detail heading={'Programming Language'} text={languages.map((text,index) => <List key={index} text={text}/>)}/>
        </Grid>
      </Grid>
      
      {/* <Detail heading={'Apps and games that match your desired look and feel'} text={'N/A'}/> */}
      {/* <Detail heading={'Links that support your project'} text={'N/A'}/> */}
      <Grid item container spacing={2}>
        <Grid item xs>
          <Detail heading={'Start date'} text={<Moment format="MMM D, YYYY" withTitle>{startDate}</Moment>}/>
        </Grid>
        <Grid item xs>
          <Detail heading={'Duration of engagement'} text={duration}/>
        </Grid>
        <Grid item xs>
          <Detail heading={'Timezone'} text={timezone}/>
        </Grid>
      </Grid>
    </Grid>
  )
}

const getStatus = ({status, updateStatus, id}) => {
  switch(status) {
    case null:
      return {
        label: <Button variant="contained" onClick= {() => updateStatus('accept',id)}>Accept</Button>, 
        value: <Button variant="outlined" onClick= {() => updateStatus('reject',id)}>Reject</Button>,
        alignItems: 'center'
      }
    case 'reject' || 'reject_client':
      return { 
        label: <RejectedIcon/>, value: 'Match Rejected',
        alignItems: 'center'
      }
    default:
      return { 
        label: <AcceptedIcon/>, value: 'Match Accepted',
        alignItems: 'center'
      }
  }
}

const Match = ({ data, updateStatus }) => {
  
  const { technicalRequirement, status, id } = data;
  const { project, domain, startDate } = technicalRequirement;
  const { user } = project;
  const { username } = user;

  
  const dataRows = [
    { label: <MyAvatar size="large" user={user} hasMenu={false}/>, value: '', xs: 'auto'},
    { label: username, value: 'Needs a Technical Project Manager in '+ domain.join(', ')},
    { label: 'Likely Start Date', value: <Moment format="MMM D, YYYY" withTitle >{startDate}</Moment>, alignItems:'center'},
    getStatus({updateStatus, status, id}),
  ]

  return (
    <Panel data={dataRows}> 
    {
      <ProjectView data={technicalRequirement}/>
    }
    </Panel>
  )
}

class Matches extends React.Component {

  updateStatus = (status,id) => {

    this.props.updateMatch(status,id).then(resp => {
      this.props.data.refetch();
    });
  }

  render() {
    const { data } = this.props;
    const { loading, user = {}} = data;
	  const { talentMatches } = user;
	
	  if(loading)
		  return <Loader/>
    
    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Matched Projects
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Recent Matched Projects
                </Typography>
                <Divider/>
                {
                  talentMatches.length === 0 &&
                  <Typography>You have not been matched against a client requirement yet</Typography>
                }
                {
                  talentMatches.map(req => <Match key={req.id} updateStatus={this.updateStatus} data={req}/>)
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

export default graphql(fetchUserMatches,{
  options: (props) => { 
    return {variables: {id: props.match.params.userId}}
  }
})(connect(mapStateToProps,{ updateMatch })(Matches))